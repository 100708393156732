<template>
  <v-container class="pa-0">
    <v-row v-if="details.categories.length">
      <v-col cols="12">
        <v-card flat class="mb-4">
          <CustomInfo  :data="details.categories" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card flat class="mb-4">
          <v-list dense>
            <v-subheader class="subheader">Allgemein</v-subheader>

            <ListItem
              v-if="details.dea"
              label="Post-Unternehmen"
              :value="details.dea"
            />

            <ListItem
              v-if="details.product"
              label="Frankierart und Version"
              :value="details.product.caption"
            />

            <ListItem
              v-if="details.customer"
              label="Kundennummer"
              :value="details.customer"
            />

            <ListItem
              v-if="details.newspaperCode"
              label="ZKZ"
              :value="details.newspaperCode"
            />

            <ListItem
              v-if="details.bookletNumber"
              label="Heftnummer"
              :value="details.bookletNumber.toString().padStart(4, '0')"
            />

            <ListItem
              v-if="details.subscriberNumber"
              label="Abonnentennummer"
              :value="details.subscriberNumber"
            />

            <ListItem
              v-if="details.costs"
              label="Entgelt oder Frankierwert"
              :value="
                'EUR ' +
                details.costs.euro +
                ',' +
                details.costs.cent.toString().padStart(2, '0')
              "
            />

            <ListItem
              v-if="details.date"
              label="Einlieferungs-/DV-Datum"
              :value="
                details.date.day.toString().padStart(2, '0') +
                '.' +
                details.date.month.toString().padStart(2, '0') +
                '.' +
                details.date.year
              "
            />

            <ListItem
              v-if="details.trackingNumber"
              label="Laufende Sendungsnummer"
              :value="details.trackingNumber"
            />

            <ListItem
              v-if="details.trackMatchId"
              label="TrackAndMatchSdgId"
              :value="details.trackMatchId"
            />

            <ListItem
              v-if="details.amNumber"
              label="AM-Nummer"
              :value="details.amNumber"
            />
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat v-if="details.recipient" class="mb-4">
          <v-list dense>
            <v-subheader class="subheader">Empfänger</v-subheader>
            <ListItem
              v-if="details.recipient.name1"
              label="Name 1"
              :value="details.recipient.name1"
            />
            <ListItem
              v-if="details.recipient.name2"
              label="Name 2"
              :value="details.recipient.name2"
            />
            <ListItem
              v-if="details.recipient.name3"
              label="Name 3"
              :value="details.recipient.name3"
            />
            <ListItem
              v-if="details.recipient.street"
              label="Straßenname"
              :value="details.recipient.street"
            />
            <ListItem
              v-if="details.recipient.houseNumber"
              label="Hausnummer"
              :value="details.recipient.houseNumber"
            />
            <ListItem
              v-if="details.recipient.zipCode"
              label="PLZ"
              :value="details.recipient.zipCode"
            />
            <ListItem
              v-if="details.recipient.city"
              label="Ort"
              :value="details.recipient.city"
            />
            <ListItem
              v-if="details.recipient.data"
              label="Kundenindividuelle Information"
              :value="details.recipient.data"
            />
          </v-list>
        </v-card>
        <v-card elevation="1" class="mb-4">
          <v-list dense>
            <v-subheader class="subheader">Erweitert</v-subheader>

            <ListItem
              v-if="details.premiumadressId"
              label="PremiumadressId"
              :value="details.premiumadressId"
            />

            <ListItem
              v-if="details.edsNumber"
              label="EDS-Nr."
              :value="details.edsNumber"
            />

            <ListItem
              v-if="details.participationNumber"
              label="Teilnahme-Nr."
              :value="details.participationNumber"
            />

            <ListItem
              v-if="details.payrollNumber"
              label="Entgeltabrechnungsnummer"
              :value="details.payrollNumber"
            />

            <ListItem
              v-if="details.customerData"
              label="Kundenindividuelle Information"
              :value="details.customerData"
            />
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ListItem from './ListItem.vue';
import CustomInfo from './CustomInfo.vue';

export default {
  name: 'Details',
  components: {
    ListItem,
    CustomInfo,
  },
  computed: {
    ...mapGetters('scans', {
      details: 'details',
    }),
  },
};
</script>

<style scoped>
.subheader {
  text-transform: uppercase;
}

.custom-info:nth-of-type(odd) {
  background: #f3f3f3;
}

.custom-info:nth-of-type(even) {
  background: #f9f9f9;
}
</style>
