export default {
  auth(state, key) {
    state.auth = key;
  },
  loading(state, loading) {
    state.loading = loading;
  },
  error(state, error) {
    state.error = error;
  },
};
