const hexEncode = (arr) => {
  let result = '';
  arr.forEach((item) => {
    result += item.toString(16).padStart(2, '0');
  });

  return result;
};

const hexDecode = (str) => {
  let result = '';
  for (let i = 0; i < str.length; i += 2) {
    result += String.fromCharCode(parseInt(str.substr(i, 2), 16));
  }

  return result;
};

const parseLvmData = (hex) => {
  const length = 28;
  const id = hex.replace(/ /g, '').trim().substr(0, length);
  let vs = -1;
  let valid = false;

  if (id.length === length) {
    valid = true;
    if (id.substr(-6, 2) !== '00') {
      if (id.substr(-2) === '00' || id.substr(-2) === '01') {
        vs = Number(id.substr(-2));
      }
    }
  }

  return {
    valid,
    id,
    vs,
  };
};

export {
  hexEncode,
  hexDecode,
  parseLvmData,
};
