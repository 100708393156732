import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import api from './api';
import device from './device';
import scans from './scans';
import settings from './settings';
import user from './user';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    api,
    device,
    scans,
    settings,
    user,
  },
  plugins: [
    createPersistedState({
      paths: ['scans.scans', 'api.auth', 'settings'],
    }),
  ],
});

export default store;
