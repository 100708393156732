<template>
  <div class="main px-4">
    <v-card v-if="selected && details" scrollable class="my-4"></v-card>

    <v-card>
      <v-data-table
        dense
        :headers="headers"
        :items="filteredItems"
        :item-class="itemClass"
        :single-expand="true"
        :expanded.sync="selectedRow"
        @click:row="handleRow"
        v-model="selection"
        :show-select="true"
        no-data-text="Keine Daten vorhanden."
        :footer-props="{
          'items-per-page-all-text': 'Alle',
          'items-per-page-text': 'Einträge pro Seite'
        }"
      >
        <template
          v-slot:[`header.lvmVs`]="{ header }"
        >
          <div
            class="text-no-wrap d-flex align-center justify-center"
          >
            <v-checkbox
              :value="filters.includes('vs')"
              @change="toggleFilter('vs')"
              :label="header.text"
              class="checkbox"
            ></v-checkbox>
          </div>
        </template>

        <template
          v-for="(category, index) in this.categories"
          v-slot:[`header.custom.category-${category.id}`]="{ header }"
        >
          <div
            :key="index"
            class="text-no-wrap d-flex align-center justify-center"
          >
            <v-checkbox
              :value="filters.includes(category.id)"
              @change="toggleFilter(category.id)"
              :label="header.text"
              class="checkbox"
            ></v-checkbox>
          </div>
        </template>

        <template
          v-for="(category, index) in this.categories"
          v-slot:[`item.custom.category-${category.id}`]="{ item }"
        >
          <div :key="index" class="d-flex justify-center align-center">
            <v-icon
              v-if="item.custom[`category-${category.id}`]"
              class="self-align-center"
              @click="category()"
            >
              mdi-check
            </v-icon>
          </div>
        </template>

        <template
          v-slot:[`item.lvmVs`]="{ item }"
        >
          <div class="d-flex justify-center align-center">
            <v-icon
              v-if="item.lvmVs === 0"
              class="self-align-center"
            >
              mdi-close
            </v-icon>
            <v-icon
              v-else-if="item.lvmVs === 1"
              class="self-align-center"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-else
              class="self-align-center"
              small
            >
              mdi-help
            </v-icon>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
             class="item-expanded pa-0 ma-0"
          >
            <v-row class="pa-0 ma-0">
              <v-col class="ma-0 pa-2">
                <v-list dense flat class="pa-0 ma-0 rounded">
                  <v-subheader class="subheader">Produkt</v-subheader>
                  <ListItem
                    v-if="item.product.caption && item.product.caption"
                    label="Produkt"
                    dense
                    :value="item.product.caption"
                    class="rounded"
                  />
                </v-list>
              </v-col>
              <v-col class="ma-0 pa-2">
                <CustomInfo :data="item.categories" class="rounded" />
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="item-actions">
            <v-btn icon>
              <v-icon @click="dialog(true)">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon @click="exportAll({ headers, items: [item] })">mdi-export</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon @click="removeConfirm(item);" color="error">mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:footer.prepend>
          <div class="selection-actions" v-if="selection.length">
            <v-btn icon>
              <v-icon @click="removeConfirm(selection)" color="error">mdi-delete</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon @click="exportAll({ headers, items: selection })">mdi-export</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }} von {{ items.itemsLength }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import formatDate from '@/utils/date';
import CustomInfo from '../components/CustomInfo.vue';
import ListItem from '../components/ListItem.vue';

export default {
  components: {
    CustomInfo,
    ListItem,
  },
  name: 'Data',
  data() {
    return {
      filters: [],
      selection: [],
      transitioned: [],
      columns: [
        {
          text: 'Gescannt',
          value: 'scan',
        },
        {
          text: 'PAL',
          value: 'date',
        },
        {
          text: 'ID',
          value: 'lvmId',
        },
        {
          text: 'VS',
          value: 'lvmVs',
          filterable: true,
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    if (this.selected === '' && this.items.length) {
      this.select(this.items[0].id);
    }
  },
  methods: {
    ...mapActions('scans', ['select', 'dialog', 'remove', 'removeAll', 'exportAll', 'category']),
    handleRow(item) {
      this.select(item.id);
    },
    toggleFilter(filter) {
      if (this.filters.includes(filter)) {
        this.removeFilter(filter);
      } else {
        this.addFilter(filter);
      }
    },
    addFilter(filter) {
      if (!this.filters.includes(filter)) {
        this.filters.push(filter);
      }
    },
    removeFilter(filter) {
      if (this.filters.includes(filter)) {
        this.filters.splice(this.filters.indexOf(filter), 1);
      }
    },
    itemClass(item) {
      return item.id === this.selected ? 'item-selected' : '';
    },
    async removeConfirm(item) {
      let title = 'Möchten Sie den Datensatz wirklich löschen?';
      if (item.length) {
        title = 'Möchten Sie die ausgewählten Datensätze löschen?';
      }

      const res = await this.$confirm(title, {
        color: 'warning',
        icon: 'warning',
      });
      if (res) {
        if (item.length) {
          this.removeAll(item);
        } else {
          this.remove(item);
        }
        this.selection = [];
      }
    },
  },
  computed: {
    ...mapGetters('scans', ['scans', 'selected', 'details', 'categories']),
    filteredItems() {
      if (!this.filters.length || !this.categories) {
        return this.items;
      }

      return this.items.filter((item) => {
        let pass = true;
        this.filters.forEach((filter) => {
          if (filter === 'vs') {
            if (item.lvmVs !== 1) {
              pass = false;
            }
          } else if (item.custom && !item.custom[`category-${filter}`]) {
            pass = false;
          }
        });
        return pass;
      });
    },
    headers() {
      return this.columns.concat(
        this.categories.map((category) => ({
          text: category.short ? category.short : category.caption,
          value: `custom.category-${category.id}`,
          filterable: true,
          sortable: false,
        })),
        [{
          text: '',
          value: 'actions',
          width: 0,
        }],
      );
    },
    items() {
      return this.scans.map((item) => {
        const result = {
          ...item,
          scan: formatDate(item.scan.date, item.scan.time),
          date: formatDate(item.date),
          selected: item.id === this.selected,
          isSelected: item.id === this.selected,
        };

        this.categories.forEach((category) => {
          if (!result.custom) {
            result.custom = {};
          }

          if (
            item.categories.includes(category.id)
          ) {
            result.custom[`category-${category.id}`] = true;
          } else {
            result.custom[`category-${category.id}`] = false;
          }
        });

        return result;
      });
    },
    selectedRow: {
      get() {
        const index = this.filteredItems.findIndex(
          (item) => item.id === this.selected,
        );
        return [
          this.filteredItems[index]
            ? this.filteredItems[index]
            : this.filteredItems[0],
        ];
      },
      set(item) {
        if (!item.length) {
          return;
        }
        this.$store.commit('scans/selected', item[0].id);
      },
    },
  },
};
</script>

<style>
.main {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.v-data-table table thead th,
.v-data-table table tbody td,
.v-data-table .v-data-footer {
  white-space: nowrap !important;
  font-size: 0.85rem !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.v-data-table table thead th label.v-label {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 40px;
  font-size: 0.85rem;
}

.item-selected {
  animation-name: item-selected-animation;
  animation-duration: 1s;
  background-color: #b6e6c5 !important;
}

.item-selected td {
  border-bottom: 0 !important;
  border-top: 4px solid #009b31 !important
}

.item-expanded {
  animation-name: item-selected-animation;
  animation-duration: 1s;
  background-color: #b6e6c5 !important;
  border-bottom: 4px solid #009b31 !important;
}

.item-actions {
  display: flex;
}

@keyframes item-selected-animation {
  from {
    background-color: green;
    color: white;
  }
  to {
    background-color: lightgreen;
    color: inherit;
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave {
  overflow: hidden;
  max-height: 1000px;
}

.slide-down-enter,
.slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
