<template>
  <v-app>
    <v-app-bar
      id="app-bar"
      app
      dark
      color="primary"
      elevation="1"
      clipped-right
    >
      <v-app-bar-nav-icon
        v-if="navigation"
        @click="navigation = !navigation"
        :disabled="!user"
      />
      <v-toolbar-title class="fill-height pa-2 d-flex">
        <img src="@/assets/logo-lvm.svg" class="logo" />
        <span class="title">DMCode-Lesung</span>
      </v-toolbar-title>
      <v-spacer />
      <Support />
      <Configuration v-if="user" />
      <User v-if="user" />
    </v-app-bar>

    <Device v-if="user" />

    <v-dialog
      v-model="warning"
      width="360px"
      max-height="200px"
      class="elevation-10"
    >
      <v-card>
        <v-card-title>Hinweis</v-card-title>
        <v-card-text
          >Zu diesem Datensatz ist keine Versicherungsscheinnummer hinterlegt
        </v-card-text>
        <v-card-actions class="justify-center pb-4">
          <v-btn text class="warning" @click="closeWarning">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main>
      <v-system-bar color="error" dark v-if="error" class="error-bar">
        <span class="error-title" v-if="error.title">{{ error.title }}</span>
        <span class="error-message">{{ error.message }}</span>
      </v-system-bar>

      <div class="fill-height d-flex">
        <transition name="fade">
          <router-view></router-view>
        </transition>

        <v-navigation-drawer
          v-if="barcodes && user"
          id="barcodes-drawer"
          right
          stateless
          clipped
          v-model="barcodes"
          width="240"
        >
          <div class="d-flex align-center pa-4">
            <v-icon class="mr-2">mdi-barcode-scan</v-icon>
            <span class="flex-grow-1">Konfiguration</span>
            <v-btn @click="detachBarcodes" icon class="align-self-end">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <Barcodes />
        </v-navigation-drawer>
      </div>
    </v-main>

    <v-footer app elevation="1">&copy; {{ year }} </v-footer>

    <Dialog />
    <Sound v-if="user" />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import User from '../components/User.vue';
import Configuration from '../components/Configuration.vue';
import Barcodes from '../components/Barcodes.vue';
import Support from '../components/Support.vue';
import Device from '../components/Device.vue';
import Dialog from '../components/Dialog.vue';
import Sound from '../components/Sound.vue';

export default {
  name: 'Index',
  components: {
    User,
    Configuration,
    Support,
    Device,
    Dialog,
    Barcodes,
    Sound,
  },
  data() {
    return {
      navigation: null,
      items: [
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          to: '/',
        },
        {
          title: 'Erfassung',
          icon: 'mdi-table',
          to: '/data/',
        },
        {
          title: 'Profile',
          icon: 'mdi-account',
          to: '/profile/',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('api', ['loading', 'error']),
    ...mapGetters('user', ['user']),
    ...mapGetters('scans', ['details']),
    dialog: {
      get() {
        return this.$store.getters['scans/dialog'];
      },
      set(value) {
        this.$store.commit('scans/dialog', value);
      },
    },
    warning: {
      get() {
        return this.$store.getters['user/warning'];
      },
      set(value) {
        this.$store.commit('user/warning', value);
      },
    },
    barcodes: {
      get() {
        return this.$store.getters['settings/barcodes'];
      },
      set(value) {
        this.$store.commit('settings/barcodes', value);
      },
    },
    year() {
      const now = new Date();
      return now.getFullYear();
    },
  },
  methods: {
    ...mapActions('scans', ['deselect']),
    detachBarcodes() {
      this.barcodes = false;
    },
    closeWarning() {
      this.$store.dispatch('user/warning', false);
    },
  },
};
</script>

<style>
#app-bar {
  justify-content: center;
}

.logo {
  max-height: 100%;
  width: auto;
}

.title {
  padding-left: 2rem;
  align-self: center;
}

.subheader {
  text-transform: uppercase;
}

.error-bar {
  display: flex;
  justify-content: center;
  position: relative !important;
}

.error-bar .error-title {
  padding: 0 16px;
  font-weight: bold;
}
</style>
