<template>
  <v-list dense class="pa-0 ma-0">
    <v-subheader class="subheader">
      Individuelle Information
    </v-subheader>
    <v-chip-group class="pa-0 px-2 ma-0">
      <v-chip
        v-for="(item, index) in data"
        :key="index"
        close
        color="primary"
        @click:close="category()"
      >
        {{ categories.find((category) => category.id === item).caption }}
      </v-chip>
    </v-chip-group>
  </v-list>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CustomInfo',
  props: {
    data: Array,
  },
  computed: {
    ...mapGetters('scans', ['categories']),
  },
  methods: {
    ...mapActions('scans', ['category']),
  },
};
</script>

<style scoped>
.custom-info:nth-of-type(odd) {
  background: #f3f3f3;
}

.custom-info:nth-of-type(even) {
  background: #f9f9f9;
}
</style>
