<template>
  <v-dialog transition="scale-transition" v-model="dialog" width="480">
    <template v-slot:activator="{ attrs, on }">
      <v-btn color="secondary" text small v-bind="attrs" v-on="on" exact>
        <v-icon class="mr-2">mdi-key</v-icon> Passwort ändern
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex">
        <v-icon class="mr-2">mdi-key</v-icon>
        <span class="flex-grow-1 text-subtitle-1">Passwort ändern</span>
      </v-card-title>
      <v-container>
        <validation-observer ref="observer" v-slot="{ untouched }">
          <v-alert v-if="error" color="warning">
            <h4>
              {{ error.title }}
              <span v-if="error.code">({{ error.code }})</span>
            </h4>
            <p>{{ error.message }}</p>
          </v-alert>
          <v-card v-if="message" flat>
            <v-alert color="success" class="white--text">
              <h4>{{ message.title }}</h4>
              <p v-if="message.message">{{ message.message }}</p>
            </v-alert>
            <v-card-actions class="text-center justify-center">
              <v-btn color="secondary" @click="dialog = false"> OK </v-btn>
            </v-card-actions>
          </v-card>

          <v-form
            v-if="!message"
            @submit.prevent="changePasswordSubmit"
            :validate="false"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Aktuelles Passwort"
              rules="required"
            >
              <v-text-field
                v-model="data.current"
                :error-messages="errors"
                label="Aktuelles Passwort"
                type="password"
                placeholder=" "
                autocomplete="current-password"
                required
              ></v-text-field>
            </validation-provider>
            <v-alert color="grey lighten-4">
              <h4>Hinweis</h4>
              <p>Das Passwort muss die folgenden Kriterien erfüllen</p>
              <ul>
                <li>Mindestlänge 12 Zeichen</li>
                <li>Kleinbuchstaben, Großbuchstaben und Ziffern</li>
              </ul>
            </v-alert>
            <validation-provider
              v-slot="{ errors }"
              name="Neues Passwort"
              rules="required|min:12|lowCase|upCase|number"
              vid="confirm"
            >
              <v-text-field
                v-model="data.password"
                :error-messages="errors"
                label="Neues Passwort"
                type="password"
                placeholder=" "
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Neues Passwort wiederholen"
              rules="required|confirmed:confirm"
            >
              <v-text-field
                v-model="data.repassword"
                :error-messages="errors"
                label="Neues Passwort wiederholen"
                type="password"
                placeholder=" "
                required
              ></v-text-field>
            </validation-provider>

            <v-card-actions class="justify-center">
              <v-btn color="grey lighten-4" @click="dialog = false">
                Abbrechen
              </v-btn>
              <v-btn
                type="submit"
                :disabled="untouched"
                :loading="loading"
                color="primary"
              >
                Passwort ändern
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { required, min, confirmed } from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Das Feld darf nicht leer sein.',
});

extend('min', {
  ...min,
  message: 'Das Passwort muss mindestens 12 Zeichen lang sein.',
});

extend('lowCase', {
  validate: (value) => value.match(/[a-z]/g) !== null,
  message: 'Das Passwort muss mindestens einen Kleinbuchstaben enthalten',
});

extend('upCase', {
  validate: (value) => value.match(/[A-Z]/g) !== null,
  message: 'Das Passwort muss mindestens einen Großbuchstaben enthalten',
});

extend('number', {
  validate: (value) => value.match(/[0-9]/g) !== null,
  message: 'Das Passwort muss mindestens eine Ziffer enthalten',
});

extend('confirmed', {
  ...confirmed,
  message: 'Beide Passwortangaben müssen übereinstimmen',
});

export default {
  name: 'ChangePassword',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      message: null,
      error: null,
      errors: [],
      data: {
        current: '',
        password: '',
        repassword: '',
      },
    };
  },
  watch: {
    dialog(to) {
      if (!to) {
        this.clearError();
        this.clearMessage();
        this.clearData();
      }
    },
  },
  mounted() {
    this.clearData();
    this.clearError();
    this.clearMessage();
  },
  methods: {
    ...mapActions('api', {
      changePassword: 'changePassword',
    }),
    async changePasswordSubmit() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }

      this.clearError();
      this.clearMessage();
      this.showLoading();

      this.changePassword({
        current: this.data.current,
        password: this.data.password,
      })
        .then((result) => {
          this.clearLoading();
          this.clearError();

          if (result.success) {
            this.showMessage({
              title: 'Passwort geändert',
              message: 'Das Passwort wurde erfolgreich geändert.',
            });
          } else {
            this.showError({
              code: null,
              title: null,
              message: 'Ein unbekannter Fehler ist aufgetreten.',
            });
          }
        })
        .catch((error) => {
          this.clearLoading();

          if (error.message) {
            this.showError(this.parseError(error));
          } else if (error.errors && error.errors.length) {
            this.showError(this.parseError(error.errors[0]));
          } else {
            this.showError({
              code: error.code,
              title: error.title,
              message: error.message,
            });
          }
        });
    },
    clearData() {
      this.data = {
        current: '',
        password: '',
        repassword: '',
      };
    },
    clearLoading() {
      this.loading = false;
    },
    showLoading() {
      this.loading = true;
    },
    clearError() {
      this.error = null;
    },
    showError(error) {
      this.error = {
        code: error.code ? error.code : null,
        title: error.title ? error.title : 'Fehler',
        message: error.message ? error.message : null,
      };
    },
    parseError(error) {
      const result = {
        code: null,
        message: null,
      };

      switch (error.code) {
        case 'INVALID-PASSWORD':
          // eslint-disable-next-line operator-linebreak
          result.message =
            'Das angegebene Passwort entspricht nicht den Vorgaben. Bitte überprüfen Sie Ihre Eingaben.';
          break;
        case 'WSG-005':
          // eslint-disable-next-line operator-linebreak
          result.message =
            'Das aktuelle Passwort ist nicht korrekt. Bitte überprüfen Sie Ihre Eingabe.';
          break;
        default:
          result.code = error.code;
          result.message = error.message;
      }

      return result;
    },
    clearMessage() {
      this.message = null;
    },
    showMessage(msg) {
      this.message = msg;
    },
  },
};
</script>
