export default {
  async connect({ commit, state }, result) {
    // Prompt user to select any serial port.
    const port = await navigator.serial.requestPort();

    // Wait for the serial port to open.
    await port.open({ baudRate: 9600 });

    while (port.readable) {
      const reader = port.readable.getReader();

      commit('port', port);
      commit('reader', reader);

      let buffer = [];
      let timeout = null;

      const process = () => {
        if (buffer.lastIndexOf(13) > buffer.length - 3) {
          result(buffer.slice(0, buffer.lastIndexOf(13)));

          buffer = [];
        }
      };

      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          // eslint-disable-next-line no-await-in-loop
          const { value, done } = await state.reader.read();
          if (done) {
            // reader.cancel() has been called.
            state.port.close();
            break;
          }

          for (let i = 0; i < value.length; i += 1) {
            buffer.push(value[i]);
          }

          if (timeout) {
            clearTimeout(timeout);
          }

          timeout = setTimeout(process, 250);
        }
      } catch (error) {
        console.log(error);
      }
    }

    state.port.close();
  },
  async disconnect({ commit, state }) {
    try {
      if (state.reader) {
        state.reader.cancel();
        state.reader.releaseLock();
      }

      if (state.port) {
        await state.port
          .close()
          .then(() => {
            commit('port', null);
            commit('reader', null);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
          });
      } else {
        commit('port', null);
        commit('reader', null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
};
