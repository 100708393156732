<template>
  <v-container>
    <validation-observer ref="observer" v-slot="{ untouched }">
      <v-alert v-if="error" color="warning">
        <h4>
          {{ error.title }}
          <span v-if="error.code">({{ error.code }})</span>
        </h4>
        <p>{{ error.message }}</p>
      </v-alert>
      <v-alert v-if="message" color="success">
        <h4>{{ message.title }}</h4>
        <p v-if="message.message">{{ message.message }}</p>
      </v-alert>

      <v-form v-if="!message" @submit.prevent="loginSubmit" :validate="false">
        <validation-provider
          v-slot="{ errors }"
          name="E-Mail-Adresse"
          rules="required|email"
        >
          <v-text-field
            v-model="data.username"
            :error-messages="errors"
            label="E-Mail-Adresse"
            type="email"
            placeholder=" "
            autocomplete="username"
            autofocus
            required
          ></v-text-field>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          name="Passwort"
          rules="required"
        >
          <v-text-field
            v-model="data.password"
            :error-messages="errors"
            label="Passwort"
            type="password"
            placeholder=" "
            autocomplete="current-password"
            required
          ></v-text-field>
        </validation-provider>

        <v-card-actions class="justify-center">
          <v-btn
            type="submit"
            :disabled="untouched"
            :loading="loading"
            color="primary"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { required, email } from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Das Feld {_field_} darf nicht leer sein.',
});

extend('email', {
  ...email,
  message: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
});

export default {
  name: 'Login',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      message: null,
      error: null,
      data: {
        username: '',
        password: '',
      },
    };
  },
  mounted() {
    this.clearError();
    this.clearMessage();
  },
  methods: {
    ...mapActions('api', {
      login: 'login',
    }),
    ...mapActions('user', {
      user: 'user',
    }),
    loginSubmit() {
      this.clearError();
      this.clearMessage();
      this.showLoading();

      this.login({
        username: this.data.username,
        password: this.data.password,
      })
        .then((result) => {
          this.clearLoading();
          if (result.user) {
            this.user({
              username: result.user,
            });
            this.$router.push({ name: 'data' });
          }
        })
        .catch((error) => {
          this.clearLoading();
          this.showError({
            code: error.code,
            title: error.title,
            message: error.message,
          });
        });
    },
    clearLoading() {
      this.loading = false;
    },
    showLoading() {
      this.loading = true;
    },
    clearError() {
      this.error = null;
    },
    showError(error) {
      this.error = {
        code: error.code ? error.code : 100,
        title: error.title ? error.title : 'Fehler',
        message: error.message ? error.message : null,
      };
    },
    clearMessage() {
      this.message = null;
    },
    showMessage(msg) {
      this.message = msg;
    },
  },
};
</script>
