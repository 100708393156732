import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VuetifyConfirm from 'vuetify-confirm';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#009b31',
        secondary: '#ff9900',
      },
      dark: {
        primary: '#009b31',
        secondary: '#ff9900',
      },
    },
  },
});

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Ja',
  buttonFalseText: 'Nein',
  color: 'warning',
  icon: 'warning',
});

export default vuetify;
