var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main px-4"},[(_vm.selected && _vm.details)?_c('v-card',{staticClass:"my-4",attrs:{"scrollable":""}}):_vm._e(),_c('v-card',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.filteredItems,"item-class":_vm.itemClass,"single-expand":true,"expanded":_vm.selectedRow,"show-select":true,"no-data-text":"Keine Daten vorhanden.","footer-props":{
        'items-per-page-all-text': 'Alle',
        'items-per-page-text': 'Einträge pro Seite'
      }},on:{"update:expanded":function($event){_vm.selectedRow=$event},"click:row":_vm.handleRow},scopedSlots:_vm._u([{key:"header.lvmVs",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"text-no-wrap d-flex align-center justify-center"},[_c('v-checkbox',{staticClass:"checkbox",attrs:{"value":_vm.filters.includes('vs'),"label":header.text},on:{"change":function($event){return _vm.toggleFilter('vs')}}})],1)]}},_vm._l((this.categories),function(category,index){return {key:("header.custom.category-" + (category.id)),fn:function(ref){
      var header = ref.header;
return [_c('div',{key:index,staticClass:"text-no-wrap d-flex align-center justify-center"},[_c('v-checkbox',{staticClass:"checkbox",attrs:{"value":_vm.filters.includes(category.id),"label":header.text},on:{"change":function($event){return _vm.toggleFilter(category.id)}}})],1)]}}}),_vm._l((this.categories),function(category,index){return {key:("item.custom.category-" + (category.id)),fn:function(ref){
      var item = ref.item;
return [_c('div',{key:index,staticClass:"d-flex justify-center align-center"},[(item.custom[("category-" + (category.id))])?_c('v-icon',{staticClass:"self-align-center",on:{"click":function($event){return category()}}},[_vm._v(" mdi-check ")]):_vm._e()],1)]}}}),{key:"item.lvmVs",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[(item.lvmVs === 0)?_c('v-icon',{staticClass:"self-align-center"},[_vm._v(" mdi-close ")]):(item.lvmVs === 1)?_c('v-icon',{staticClass:"self-align-center"},[_vm._v(" mdi-check ")]):_c('v-icon',{staticClass:"self-align-center",attrs:{"small":""}},[_vm._v(" mdi-help ")])],1)]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{staticClass:"item-expanded pa-0 ma-0",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"ma-0 pa-2"},[_c('v-list',{staticClass:"pa-0 ma-0 rounded",attrs:{"dense":"","flat":""}},[_c('v-subheader',{staticClass:"subheader"},[_vm._v("Produkt")]),(item.product.caption && item.product.caption)?_c('ListItem',{staticClass:"rounded",attrs:{"label":"Produkt","dense":"","value":item.product.caption}}):_vm._e()],1)],1),_c('v-col',{staticClass:"ma-0 pa-2"},[_c('CustomInfo',{staticClass:"rounded",attrs:{"data":item.categories}})],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"item-actions"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.dialog(true)}}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.exportAll({ headers: _vm.headers, items: [item] })}}},[_vm._v("mdi-export")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeConfirm(item);}}},[_vm._v("mdi-delete")])],1)],1)]}},{key:"footer.prepend",fn:function(){return [(_vm.selection.length)?_c('div',{staticClass:"selection-actions"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeConfirm(_vm.selection)}}},[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.exportAll({ headers: _vm.headers, items: _vm.selection })}}},[_vm._v("mdi-export")])],1)],1):_vm._e()]},proxy:true},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" von "+_vm._s(items.itemsLength)+" ")]}}],null,true),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }