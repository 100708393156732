import router from '../../router';

export default {
  user({ commit }, user) {
    const permissions = {
      changePassword: true,
      resetData: true,
    };

    commit('user', {
      ...user,
      permissions,
    });
  },
  warning({ commit }, warning) {
    commit('warning', warning);
  },
  notify({ commit }) {
    commit('sound', true);
    setTimeout(() => {
      commit('sound', false);
    }, 500);
  },
  logout({ dispatch, commit }) {
    dispatch('device/disconnect', null, { root: true });
    commit('user', null);

    router.push('/login');
  },
};
