var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"scale-transition","width":"480"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","text":"","small":"","exact":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-key")]),_vm._v(" Passwort ändern ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-key")]),_c('span',{staticClass:"flex-grow-1 text-subtitle-1"},[_vm._v("Passwort ändern")])],1),_c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var untouched = ref.untouched;
return [(_vm.error)?_c('v-alert',{attrs:{"color":"warning"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.error.title)+" "),(_vm.error.code)?_c('span',[_vm._v("("+_vm._s(_vm.error.code)+")")]):_vm._e()]),_c('p',[_vm._v(_vm._s(_vm.error.message))])]):_vm._e(),(_vm.message)?_c('v-card',{attrs:{"flat":""}},[_c('v-alert',{staticClass:"white--text",attrs:{"color":"success"}},[_c('h4',[_vm._v(_vm._s(_vm.message.title))]),(_vm.message.message)?_c('p',[_vm._v(_vm._s(_vm.message.message))]):_vm._e()]),_c('v-card-actions',{staticClass:"text-center justify-center"},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" OK ")])],1)],1):_vm._e(),(!_vm.message)?_c('v-form',{attrs:{"validate":false},on:{"submit":function($event){$event.preventDefault();return _vm.changePasswordSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Aktuelles Passwort","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Aktuelles Passwort","type":"password","placeholder":" ","autocomplete":"current-password","required":""},model:{value:(_vm.data.current),callback:function ($$v) {_vm.$set(_vm.data, "current", $$v)},expression:"data.current"}})]}}],null,true)}),_c('v-alert',{attrs:{"color":"grey lighten-4"}},[_c('h4',[_vm._v("Hinweis")]),_c('p',[_vm._v("Das Passwort muss die folgenden Kriterien erfüllen")]),_c('ul',[_c('li',[_vm._v("Mindestlänge 12 Zeichen")]),_c('li',[_vm._v("Kleinbuchstaben, Großbuchstaben und Ziffern")])])]),_c('validation-provider',{attrs:{"name":"Neues Passwort","rules":"required|min:12|lowCase|upCase|number","vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Neues Passwort","type":"password","placeholder":" ","required":""},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Neues Passwort wiederholen","rules":"required|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Neues Passwort wiederholen","type":"password","placeholder":" ","required":""},model:{value:(_vm.data.repassword),callback:function ($$v) {_vm.$set(_vm.data, "repassword", $$v)},expression:"data.repassword"}})]}}],null,true)}),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"grey lighten-4"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"type":"submit","disabled":untouched,"loading":_vm.loading,"color":"primary"}},[_vm._v(" Passwort ändern ")])],1)],1):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }