<template>
  <v-menu
    bottom
    left
    min-width="320"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="ml-2 pa-0" text v-bind="attrs" v-on="on" exact>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list :tile="false" flat nav dense>
      <v-subheader>
        <v-icon class="mr-2">mdi-account</v-icon>
        {{ user.username }}
      </v-subheader>
      <v-divider class="mb-2 mt-2" />
      <v-list-item v-if="user.permissions.resetData">
        <ResetData />
      </v-list-item>
      <v-list-item v-if="user.permissions.changePassword">
        <ChangePassword />
      </v-list-item>
      <v-list-item class="d-flex justify-end">
        <v-spacer />
        <v-btn small @click.prevent="logoutSubmit" color="secondary">
          <v-icon size="small" class="mr-2">mdi-logout</v-icon> Logout
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ChangePassword from './ChangePassword.vue';
import ResetData from './ResetData.vue';

export default {
  name: 'User',
  components: {
    ChangePassword,
    ResetData,
  },
  methods: {
    ...mapActions('user', {
      logout: 'logout',
    }),
    logoutSubmit() {
      this.logout();
    },
  },
  computed: {
    ...mapGetters('user', {
      user: 'user',
    }),
  },
};
</script>
