import axios from 'axios';
import axiosRetry from 'axios-retry';
import api from './constants';

import ApiError from '@/utils/error';

export default {
  async auth({ dispatch, commit }) {
    axiosRetry(axios, {
      retries: api.retries.auth,
      retryDelay: axiosRetry.exponentialDelay,
    });

    try {
      const response = await axios({
        method: 'post',
        url: api.host + (api.port ? `:${api.port}` : '') + api.paths.auth,
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': api.key,
        },
        withCredentials: false,
        timeout: api.timeout.default,
      });

      if (
        response
        && response.data
        && response.data.successful
        && response.data.token
        && response.data.lifetime
      ) {
        const now = new Date();
        commit('auth', {
          token: response.data.token,
          expires: new Date(now.getTime() + response.data.lifetime * 1000),
        });
        return true;
      }
    } catch (error) {
      dispatch('error', {
        code: 102,
        message: 'authentication error',
      });
    }
    return false;
  },
  async request({ dispatch, state }, { method, path, data }) {
    dispatch('loading', {
      type: 'request',
    });
    dispatch('error', null);

    if (!api.host) {
      throw new Error('API request error: missing api host');
    }

    if (!api.key) {
      throw new Error('API request error: missing api key');
    }

    if (
      !state.auth
      || (state.auth
        && state.auth.expires
        && new Date(state.auth.expires).getTime() < new Date().getTime())
    ) {
      await dispatch('auth');
    }

    return new Promise((resolve, reject) => {
      if (state.request) {
        state.request.cancel();
      }

      state.request = axios.CancelToken.source();

      axiosRetry(axios, {
        retries: api.retries.default,
        retryDelay: axiosRetry.exponentialDelay,
      });

      axios({
        method,
        url: api.host + (api.port ? `:${api.port}` : '') + path,
        data,
        cancelToken: state.request.token,
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.auth.token}`,
          'X-API-Key': api.key,
        },
        timeout: api.timeout.decode ? api.timeout.decode : api.timeout.default,
      })
        .then((response) => {
          dispatch('loading', false);
          if (response.data) {
            resolve(response.data);
          } else {
            dispatch('error', {
              code: 102,
              message: 'request response malformed',
            });
            reject(new Error('API request error: response malformed'));
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }
          if (error.response) {
            reject(error.response.data);
            return;
          }
          dispatch('error', {
            code: 103,
            message: 'network error',
          });
          reject(new ApiError(`API request error: ${error}`));
        });
    });
  },
  login({ commit, dispatch }, { username, password }) {
    return new Promise((resolve, reject) => {
      dispatch('request', {
        method: 'post',
        path: api.paths.login,
        data: {
          username,
          password,
        },
      })
        .then((result) => {
          if (result.successful) {
            const now = new Date();
            commit('auth', {
              token: result.token,
              expires: new Date(now.getTime() + result.lifetime * 1000),
            });

            resolve({
              user: username,
            });
          } else {
            reject(new ApiError(
              null,
              'Login fehlgeschlagen',
              'Benutzername oder Passwort nicht korrekt.',
            ));
          }
        })
        .catch(() => {
          reject(
            new ApiError(
              101,
              'Server nicht erreichbar',
              'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
            ),
          );
        });
    });
  },
  resetPassword({ dispatch }, { email }) {
    return new Promise((resolve, reject) => {
      dispatch('request', {
        method: 'post',
        path: api.paths.resetPassword,
        data: {
          email,
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(
            new ApiError(
              101,
              'Server nicht erreichbar',
              'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
            ),
          );
        });
    });
  },
  changePassword({ dispatch, rootGetters }, { current, password }) {
    return new Promise((resolve, reject) => {
      const user = rootGetters['user/user'];

      if (!user) {
        console.log('user required');
        dispatch('user/logout', null, { root: true });
      }

      dispatch('request', {
        method: 'post',
        path: api.paths.changePassword,
        data: {
          username: user.username,
          currentPassword: current,
          newPassword: password,
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  register({ dispatch }, { username, password }) {
    return new Promise((resolve, reject) => {
      dispatch('request', {
        method: 'post',
        path: api.paths.register,
        data: {
          username,
          password,
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(
            new Error({
              title: 'Registrierung fehlgeschlagen',
              message:
                'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
            }),
          );
        });
    });
  },
  decode({ dispatch }, code) {
    return new Promise((resolve, reject) => {
      dispatch('request', {
        method: 'post',
        path: api.paths.decode,
        data: {
          code,
        },
      })
        .then((data) => {
          if (data.result) {
            if (data.ok) {
              resolve(data.result);
            } else {
              dispatch('error', {
                code: 202,
                message: data.result,
              });
              reject();
            }
          } else {
            dispatch('error', {
              code: 201,
              message: 'missing response data',
            });
            reject();
          }
        })
        .catch((error) => {
          dispatch('error', {
            code: 201,
            title: 'Hinweis',
            message: 'Der gescannte Code wird aktuell nicht unterstützt.',
          });
          reject(error);
        });
    });
  },
  loading({ commit }, loading) {
    commit('loading', loading);
  },
  error({ commit }, error) {
    commit('error', error);
  },
  cancel({ commit, state }) {
    if (state.request) {
      state.request.cancel();
    }

    commit('loading', false);
    commit('error', false);
  },
};
