const state = {
  dialog: false,
  selected: '',
  scans: [],
  categories: [
    {
      id: 1,
      caption: 'Empfänger nicht zu ermitteln',
      short: 'Nicht zu ermitteln',
    },
    {
      id: 2,
      caption: 'Empfänger verzogen',
      short: 'Verzogen',
    },
    {
      id: 3,
      caption: 'Annahme verweigert',
    },
    {
      id: 4,
      caption: 'Empfänger verstorben',
      short: 'Verstorben',
    },
    {
      id: 5,
      caption: 'Sendung wurde nicht abgeholt',
      short: 'Nicht abgeholt',
    },
  ],
};

export default state;
