import strftime from 'strftime';
import { hexEncode, hexDecode, parseLvmData } from '@/utils/code';

export default {
  async decode({ dispatch, state, rootGetters }, { code }) {
    let str = code;

    if (rootGetters['user/warning']) {
      dispatch('user/notify', null, { root: true });
      return;
    }

    if (typeof str !== 'string') {
      str = hexEncode(code);
    }

    if (str.indexOf('444541') >= 0) {
      str = str.slice(str.indexOf('444541'));

      dispatch('api/decode', str, { root: true })
        .then((result) => {
          const now = new Date();
          const data = {
            ...result,
            id: str,
            scan: {
              date: {
                day: now.getDate(),
                month: now.getMonth() + 1,
                year: now.getFullYear(),
              },
              time: {
                hour: now.getHours(),
                minute: now.getMinutes(),
              },
            },
            productDetails: {
              produktEkp: null,
            },
            categories: [],
            lvmId: null,
            lvmVs: -1,
            lvmValid: false,
          };

          const lvmData = parseLvmData(result.customerData.hex);
          if (lvmData) {
            data.lvmId = lvmData.id;
            data.lvmVs = lvmData.vs;
            data.lvmValid = lvmData.valid;

            if (data.lvmVs === 0) {
              dispatch('user/notify', null, { root: true });
              dispatch('user/warning', true, { root: true });
            }
          }

          dispatch('add', data);
          dispatch('select', str);
          dispatch('category', 1);
        })
        .catch((error) => {
          console.log(error);
        });
      return;
    }

    /* info barcode format 'LVM|id' */
    const prefix = '4c564d';
    const delim = '7c';

    if (str.indexOf(prefix) >= 0) {
      if (!state.selected) {
        console.log('no scan selected');
        return;
      }
      const part = str.split(delim);
      const category = Number(hexDecode(part[1]));

      dispatch('category', category);
      return;
    }

    dispatch('user/message', 'Der gescannte Code wird aktuell nicht unterstützt.', { root: true });
  },
  add({ commit, state }, data) {
    if (state.scans.find((item) => item.id === data.id)) {
      commit('replace', data);
    } else {
      commit('add', data);
    }
  },
  remove({ commit }, id) {
    commit('remove', id);
  },
  clear({ commit }) {
    commit('clear');
  },
  select({ commit }, id) {
    commit('selected', id);
  },
  deselect({ commit }) {
    commit('selected', null);
  },
  dialog({ commit }, value) {
    commit('dialog', value);
  },
  removeAll({ commit }, items) {
    items.forEach((item) => {
      commit('remove', item.id);
    });
  },
  exportAll(opt, { headers, items }) {
    const columns = headers.slice(0, headers.length - 1);

    const data = items.map((item) => {
      const result = [];

      columns.forEach((column) => {
        if (column.value.indexOf('.') < 0) {
          if (column.value === 'lvmVs') {
            result.push(item[column.value] < 0 ? '' : item[column.value]);
          } else {
            result.push(item[column.value]);
          }
        } else {
          const group = column.value.split('.')[0];
          const key = column.value.split('.')[1];

          result.push(item[group][key] ? 1 : 0);
        }
      });

      return result;
    });

    const csv = `data:text/csv;charset=utf-8,'${columns
      .map((column) => column.text)
      .join("','")}'\n'${data.map((e) => e.join("','")).join("'\n'")}'`;

    const encodedUri = encodeURI(csv);
    const link = document.createElement('a');

    const now = new Date();

    const filename = `lvm-${strftime('%Y%m%d-%H%M%S', now)}.csv`;

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);

    link.click();
  },
  category({ commit }, category) {
    if (category && category > 0) {
      commit('categories', category);
    } else {
      commit('categories', null);
    }
  },
};
