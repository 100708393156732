class ApiError extends Error {
  constructor(code, title = 'API Error', ...params) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = 'ApiError';
    this.title = title;
    this.code = code;

    this.date = new Date();
  }
}

export default ApiError;
