<template>
  <v-dialog
    transition="scale-transition"
    v-model="dialog"
    width="480"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="ml-2 pa-0" text v-bind="attrs" v-on="on" exact>
        <v-icon>mdi-barcode-scan</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex">
        <v-icon class="mr-2">mdi-barcode-scan</v-icon>
        <span class="flex-grow-1 text-subtitle-1">Konfiguration</span>
        <v-btn @click="printBarcodes" icon color="primary">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-btn v-if="barcodesAttached" @click="detachBarcodes" icon color="primary">
          <v-icon>mdi-pin-outline</v-icon>
        </v-btn>
        <v-btn v-else @click="attachBarcodes" icon color="primary">
          <v-icon>mdi-pin-outline</v-icon>
        </v-btn>
      </v-card-title>
      <Barcodes ref="barcodes" />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Barcodes from './Barcodes.vue';

export default {
  name: 'Configuration',
  components: {
    Barcodes,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters('settings', {
      barcodesAttached: 'barcodes',
    }),
  },
  methods: {
    ...mapActions('settings', ['barcodes']),
    attachBarcodes() {
      this.barcodes(true);
    },
    detachBarcodes() {
      this.barcodes(false);
    },
    printBarcodes() {
      const win = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      win.document.write(`<!DOCTYPE html>
      <html>
        <body style="padding: 40px;">
          <h1>LVM Retourenmanagement</h1>
          <h2>Steuerbarcodes v1.0 - Stand Dezember 2021</h2>
          ${this.$refs.barcodes.$el.innerHTML}
        </body>
      </html>`);

      win.document.close();
      win.focus();
      win.print();
      win.close();
    },
  },
};
</script>
