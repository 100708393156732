<template>
  <v-banner
    v-if="available"
    app
    single-line
    sticky
    light
    class="device"
    :class="reader ? 'connected' : 'disconnected'"
  >
    <v-icon v-if="reader" slot="icon" color="success">
      mdi-checkbox-marked
    </v-icon>
    <v-icon v-else slot="icon" color="error">
      mdi-alert-outline
    </v-icon>
    <span v-if="reader">Scanner verbunden</span>
    <span v-else>Scanner nicht verbunden</span>

    <template v-slot:actions>
      <v-btn v-if="!reader" color="error" @click="init()" small>
        Scanner verbinden
      </v-btn>
      <v-btn v-if="reader" color="primary" text @click="disconnect()" small>
        Verbindung trennen
      </v-btn>
    </template>
    <div class="device-banner"></div>
  </v-banner>
  <v-banner v-else app single-line sticky light elevation="2">
    <v-icon slot="icon" color="warning">
      mdi-alert-outline
    </v-icon>
    <span>Dieser Browser wird nicht unterstützt.</span>
  </v-banner>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Device',
  computed: {
    ...mapGetters('device', ['available', 'port', 'reader']),
  },
  mounted() {},
  beforeDestroy() {
    this.disconnect();
  },
  methods: {
    ...mapActions('device', ['connect', 'disconnect']),
    ...mapActions('scans', ['decode']),
    init() {
      this.connect((result) => {
        this.decode({
          code: result,
        });
      });
    },
  },
};
</script>

<style scoped>
  .device {
    background-color: white !important;
  }

  .device.disconnected {
    padding-bottom: 6px;
  }

  .device.disconnected .device-banner {
    content: " ";
    position: absolute;
    z-index: 20;
    left: 0;
    bottom: 0;
    background: repeating-linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      #ff3300 50%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
    min-height: 6px;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  @keyframes gradient {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
</style>
