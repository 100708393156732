import Vue from 'vue';

export default {
  add(state, result) {
    state.scans.unshift(result);
  },
  replace(state, result) {
    state.scans.splice(
      state.scans.findIndex((item) => item.id === result.id),
      1,
    );

    state.scans.unshift(result);
  },
  remove(state, id) {
    state.scans.splice(
      state.scans.findIndex((item) => item.id === id),
      1,
    );
  },
  categories(state, category) {
    const scan = state.scans.find((item) => item.id === state.selected);
    const cat = state.categories.find((item) => item.id === category);

    if (!scan) {
      return;
    }

    if (!cat) {
      Vue.set(scan, 'categories', []);
      return;
    }

    Vue.set(scan, 'categories', [category]);
  },
  clear(state) {
    state.scans = [];
  },
  selected(state, id) {
    state.selected = id;
  },
  dialog(state, value) {
    state.dialog = value;
  },
};
