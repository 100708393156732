<template>
  <v-list :tile="false" flat nav>
    <div v-for="(cat, index) in categories" :key="index">
      <v-list-item class="pa-4 justify-center">
        <barcode
          :value="`LVM|${ cat.id.toString().padStart('0', 2) }`"
          width="1"
          height="40"
          :text="cat.caption"
          font-size="12"
          tag="img"
        />
      </v-list-item>
      <v-divider v-if="index < categories.length - 1" class="mb-2 mt-2" />
    </div>
  </v-list>
</template>

<script>
import VueBarcode from 'vue-barcode';
import { mapGetters } from 'vuex';

export default {
  name: 'Configuration',
  components: {
    barcode: VueBarcode,
  },
  computed: {
    ...mapGetters('scans', ['categories']),
  },
};
</script>
