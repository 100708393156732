import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../layout/Index.vue';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import Data from '../views/Data.vue';
import Profile from '../views/Profile.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: Index,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
      },
      {
        path: '/',
        name: 'data',
        component: Data,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const user = router.$store.getters['user/user'];
  if (to.matched.some((record) => record.meta.requiresAuth) && !user) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
