<template>
  <audio ref="player" :src="ping"></audio>
</template>

<script>
import { mapGetters } from 'vuex';
import ping from '../assets/notification.wav';

export default {
  name: 'Sound',
  data() {
    return {
      ping,
    };
  },
  computed: {
    ...mapGetters('user', ['sound']),
  },
  watch: {
    sound(val) {
      if (val) {
        this.$refs.player.pause();
        this.$refs.player.currentTime = 0;
        this.$refs.player.play();
      }
    },
  },
};
</script>
