export default {
  host: process.env.VUE_APP_API_HOST
    ? process.env.VUE_APP_API_HOST
    : 'https://api.admon.de',
  port: process.env.VUE_APP_API_PORT ? process.env.VUE_APP_API_PORT : null,
  key: '06d39790-c5cd-431d-880b-95f684c5a56a',
  timeout: {
    default: 3000,
    decode: 5000,
  },
  retries: {
    default: 3,
    auth: 5,
  },
  paths: {
    auth: '/login/api-key',
    decode: '/invoke/pmc-decoder/',
    login: '/login',
    register: '/account/register',
    resetPassword: '/account/reset-password',
    changePassword: '/invoke/ims/change-password',
  },
};
