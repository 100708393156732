<template>
  <v-menu
    bottom
    left
    max-width="320"
    min-width="240"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn class="ml-2 pa-0" text v-bind="attrs" v-on="on" exact>
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </template>

    <v-list :tile="false" flat nav>
      <v-subheader>
        <v-icon class="mr-2">mdi-help-circle-outline</v-icon>
        Support
      </v-subheader>
      <v-divider class="mb-2 mt-2" />
      <v-list-item>
        <v-avatar color="primary" class="mr-2 white--text">ME</v-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Martin Elbers
          </v-list-item-title>
          <v-list-item-subtitle v-if="user">
            m.elbers@lvm.de
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="d-flex justify-end" v-if="user">
        <v-btn small href="mailto:m.elbers@lvm.de" color="secondary">
          <v-icon size="small" class="mr-2">mdi-email</v-icon>Kontakt
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Support',
  computed: {
    ...mapGetters('user', {
      user: 'user',
    }),
  },
};
</script>
