<template>
  <v-list-item class="list-item" :class="{ 'dense' : dense }">
    <v-list-item-content class="wrapper">
      <v-list-item-title class="label">{{ label }}</v-list-item-title>
      <v-list-item-content class="content"
        v-html="content"
      ></v-list-item-content>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'Details',
  props: {
    label: {
      type: String,
      default: () => '',
    },
    value: {
      type: [String, Number, Object],
      default: () => '',
    },
    html: {
      type: String,
      default: () => '',
    },
    dense: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    content() {
      let output;
      switch (typeof this.value) {
        case 'object':
          output = '<table>';
          Object.entries(this.value).forEach(([key, value]) => {
            output += `<tr><td>${key.toUpperCase()}</td><td>${value}</td></tr>`;
          });
          output += '</table>';
          return output;
        case 'string':
        case 'number':
        default:
          return this.value;
      }
    },
  },
};
</script>

<style>

.list-item.dense {
  font-size: small;
}

.list-item.dense .label,
.list-item.dense .content {
  padding: 0;
}

.list-item.dense .wrapper {
  display: flex;
  flex-direction: row;
}

.list-item:nth-of-type(odd) {
  background: #f3f3f3;
}

.list-item:nth-of-type(even) {
  background: #f9f9f9;
}

.list-item .label {
  font-size: small;
  color: var(--ion-color-medium);
  padding: 4px 0;
}

.list-item .content {
  padding: 4px 0;
}

.list-item .content table td {
  vertical-align: top;
  padding: 4px;
  font-size: small;
}

.list-item .content table td:nth-of-type(1) {
  padding-left: 0;
}
</style>
