import strftime from 'strftime';

const formatDate = (date, time = null) => {
  const ts = new Date(
    date.year,
    date.month - 1,
    date.day,
    time ? time.hour : 0,
    time ? time.minute : 0,
  );
  if (time) {
    return strftime('%d.%m.%Y %H:%M', ts);
  }

  return strftime('%d.%m.%Y', ts);
};

export default formatDate;
