export default {
  scans: (state) => state.scans,
  categories: (state) => state.categories,
  count: (state) => state.scans.length,
  selected: (state) => state.selected,
  dialog: (state) => state.dialog,
  details: (state) => state.scans.find((item) => item.id === state.selected),
  last: (state) => (state.scans.length > 0
    ? state.scans.slice(state.count - 1, 1)[0]
    : null),
};
