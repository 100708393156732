<template>
  <v-dialog
    v-model="dialog"
    width="80%"
    max-height="80%"
    scrollable
    class="elevation-10"
  >
    <v-card>
      <v-card-title>Datensatz bearbeiten</v-card-title>
      <v-card-text>
        <Details />
      </v-card-text>
      <v-card-actions>
        <v-btn text class="primary" @click="close()">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import Details from './Details.vue';

export default {
  name: 'Dialog',
  components: {
    Details,
  },
  computed: {
    dialog: {
      get() {
        return this.$store.getters['scans/dialog'];
      },
      set(value) {
        this.$store.commit('scans/dialog', value);
      },
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.subheader {
  text-transform: uppercase;
}

.custom-info:nth-of-type(odd) {
  background: #f3f3f3;
}

.custom-info:nth-of-type(even) {
  background: #f9f9f9;
}
</style>
