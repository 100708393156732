<template>
  <v-dialog
    transition="scale-transition"
    v-model="dialog"
    width="480"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn color="secondary" text small v-bind="attrs" v-on="on" exact>
        <v-icon class="mr-2">mdi-delete</v-icon> Daten zurücksetzen
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex">
        <v-icon class="mr-2">mdi-delete</v-icon>
        <span class="flex-grow-1 text-subtitle-1">Daten zurücksetzen</span>
      </v-card-title>
      <v-container>
        <v-alert v-if="!message" color="grey lighten-4">
          <h4>Hinweis</h4>
          <p>Alle bisher erfassten Daten werden gelöscht.</p>
        </v-alert>
        <v-alert v-if="message" color="green lighten-4">
          <p>Alle bisher erfassten Daten wurden erfolgreich gelöscht.</p>
        </v-alert>
      </v-container>

      <v-card-actions class="justify-center">
        <v-btn
          v-if="!message"
          @click="resetData()"
          color="primary"
        >
          Daten löschen
        </v-btn>
        <v-btn
          v-if="message"
          @click="dialog = false"
          color="secondary"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ResetData',
  data() {
    return {
      dialog: false,
      message: null,
    };
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.clearMessage();
      }
    },
  },
  methods: {
    ...mapActions('scans', {
      clear: 'clear',
    }),
    resetData() {
      this.clear();
      this.showMessage('Die erfassten Daten wurden erfolgreich zurückgesetzt.');
    },
    clearMessage() {
      this.message = null;
    },
    showMessage(msg) {
      this.message = msg;
    },
  },
};
</script>
