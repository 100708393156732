<template>
  <v-container class="py-8">
    <v-row>
      <v-col cols="4">
        <v-card min-height="300" class="d-flex flex-column">
          <v-card-title class="text-overline">
            Erfassung
          </v-card-title>
          <v-card-text>
            <p v-if="count === 1" class="text-h5 text--primary">
              {{ count }} Datensatz insgesamt
            </p>
            <p v-else class="text-h5 text--primary">
              {{ count }} Datensätze insgesamt
            </p>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">ID</th>
                  <th class="text-left">Information</th>
                  <th class="text-left">Anzahl</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(group, index) in grouped" :key="index">
                  <td>{{ group.code }}</td>
                  <td>{{ group.text }}</td>
                  <td>{{ group.count }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-spacer />
          <v-card-actions class="pa-4 align-center justify-center">
            <v-btn
              color="secondary"
              small
              to="/data"
            >
              Daten erfassen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="4" v-if="last">
        <v-card min-height="300" class="d-flex flex-column">
          <v-card-title class="text-overline">
            Zuletzt erfasst
          </v-card-title>
          <v-card-text class="fill-height">
            <p class="text-h5 text--primary">{{ last.product.caption }}</p>
            <p>{{ selected }}</p>
            <v-list dense class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Erfasst:
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ formatDate(last.scan.date, last.scan.time) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-spacer />
          <v-card-actions class="pa-4 align-center justify-center">
            <v-btn
              color="secondary"
              small
              @click="select(last.id)"
            >
              Datensatz anzeigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card min-height="300" class="d-flex flex-column">
          <v-card-title class="text-overline">
            Profil
          </v-card-title>
          <v-card-text>
            <v-list dense class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Benutzername:
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ user.username }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-spacer />
          <v-card-actions class="pa-4 align-center justify-center">
            <v-btn
              color="secondary"
              small
              to="/profile"
            >
              Profil anzeigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import formatDate from '@/utils/date';

export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters('scans', {
      count: 'count',
      last: 'last',
      selected: 'selected',
      grouped: 'grouped',
    }),
    ...mapGetters('user', {
      user: 'user',
    }),
  },
  methods: {
    ...mapActions('scans', {
      select: 'select',
    }),
    formatDate,
  },
};
</script>
