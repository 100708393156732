<template>
  <v-container>
    <v-row>
      <v-col cols="6" md="12">
        <h2>Profil</h2>
        <p>{{ user.username }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Profile',
  computed: {
    ...mapGetters('user', ['user']),
  },
};
</script>
