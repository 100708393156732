export default {
  user(state, user) {
    state.user = user;
  },
  warning(state, warning) {
    state.warning = warning;
  },
  sound(state, sound) {
    state.sound = sound;
  },
};
