<template>
  <div class="full-width fill-height flex-grow-1">
    <v-parallax
      src="@/assets/background-image-lvm.jpg"
      height="100%"
      class="fill-height full-width blur"
    >
      <v-card elevation="2" width="320" class="align-self-center">
        <LoginForm />
      </v-card>
    </v-parallax>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoginForm from '../components/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  methods: {
    ...mapActions('user', {
      login: 'login',
    }),
    loginSubmit() {
      this.login({ username: 'abc' }).then(() => {
        this.$router.push({ name: 'dashboard' });
      });
    },
  },
};
</script>

<style scoped>
  body {
    background: transparent linear-gradient(78deg,#3e7326,#97bb40 50%,#97bb40 74%,#3e7326);
  }
</style>
